import { observer } from "mobx-react";
import React from "react";
import { IonButton, IonButtons, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonReorderGroup } from "@ionic/react";
import "./styles.less";
import { computed, IObservableArray, observable, reaction, toJS } from "mobx";
import MdIcon from "../../../../components/MdIcon";
import { mdiPlus } from "@mdi/js";
import { ui } from "../../../../client/ui";
import { getEventRealValue, isEmpty, isEqual, preventDefaultStopProp, runInAsync, safeParseJSON } from "../../../../utils/helpers";
import { UIText } from "../../../../client/lang";
import { RfqFormDTO, RfqFormItemDTO } from "../../lib/types/rfqFormTypes";
import { defaultDimensionUnit, defaultWeightUnit, dimensionOptions, weightOptions } from "../../config/constants";
import { ElementType } from "../../../../lib/types/miscTypes";
import { ItemReorderEventDetail } from "@ionic/core";
import TwoStageInput from "../../../../components/TwoStageInput";
import { ellipsisHorizontal, radioButtonOffOutline, radioButtonOnOutline } from "ionicons/icons";
import { IonCheckboxWithReadonly } from "../../../../components/FormFields/Checkbox";
import { getUnits, rfqRoundDimension, rfqRoundWeight } from "../../lib/common";
import Refresher, { RefresherProps } from "../../../../components/Refresher";
import MaterialDateTimeItem from "../../../../components/MaterialDateTimeItem";
import PlacesAutocomplete from "../../../../components/FormFields/PlaceAutocomplete";
import { RfqFormFreightItem } from "./RfqFormFreightItem";
import { Form } from "../../../../client/form";
import HelpTip from "../../../../components/HelpTip";
import { PlacesAutocompleteResult } from "../../../../lib/types/dataTypes";
import { ShippingUnit } from "../../lib/types/rateTypes";

export interface RfqFormProps extends React.ComponentProps<typeof IonContent> {
  data?: RfqFormDTO;
  readonly?: boolean;
  onRefresh?: RefresherProps["onRefresh"];
}

@observer
class RfqForm extends React.Component<RfqFormProps> {
  disposers;
  formElm: HTMLIonContentElement;

  @observable shippingUnits: IObservableArray<ShippingUnit> = [] as IObservableArray<ShippingUnit>;

  @observable errors = {
    "cargoReadyDate": false,
    "pol": false,
    "pod": false,
    "goods": false,
    "portToDoor.deliveryAddress": false,
    "doorToPort.pickUpAddress": false,
    "doorToDoor.deliveryAddress": false,
    "doorToDoor.pickUpAddress": false,
    shipmentItems: false
  };

  itemErrors = [
    "quantity",
    "weight",
    "weightDispUnit",
    "notes",
    "fcl.containerType",
    "ftl.containerType",
    "lcl.measurementMode",
    "ltl.measurementMode",
    "pieces.measurementMode",
  ];
  @observable itemErrorItems = {};

  @observable data: RfqFormDTO = {...new RfqFormDTO()};
  @observable private _freightAccordionCollapses = {};

  @observable arrivalDateSelectorOpen: boolean = false;
  @observable arrivalDatePopupShowed: boolean = false;

  @computed get hasDoorToService(): boolean {
    return !!this.data.doorToDoor || !!this.data.doorToPort;
  };
  @computed get doorServicePickupAddress(): PlacesAutocompleteResult {
    return (this.data.doorToPort || this.data.doorToDoor || {}).pickUpAddress;
  };

  @computed get items(): RfqFormItemDTO[] {
    if (this.props.readonly && this.data.items && !this.data[`_${this.data.mode}_items`]) {
      return typeof this.data.items === "string"
      ? safeParseJSON(this.data.items, true)
      : this.data.items;
    }
    return this.data[`_${this.data.mode}_items`] || [];
  };

  constructor(props) {
    super(props);
    this.getShippingUnits().catch(err => ui.showError({ err }));
    if (!isEmpty(props.data) && isEmpty(this.data)) this.data = toJS(this.props.data);
    this.disposers = [
      reaction(() => toJS(this.data), this.clearErrors),
      reaction(
        () => [
          this.hasDoorToService,
          this.doorServicePickupAddress,
          (this.data.packing || {}).locationOverride
        ],
        this.autoPackingLocationValue
      )
    ];
  }

  componentDidUpdate(prevProps: Readonly<RfqFormProps>): void {
    if (isEqual(prevProps.data, this.props.data)) return;
    if (isEmpty(this.props.data) && !isEmpty(this.data)) {
      this.reset();
    } else {
      this.reset();
      this.data = toJS(this.props.data);
    }
  }

  componentWillUnmount(): void {
    this.disposers && this.disposers.map(disposer => disposer());
  }

  reset = () => this.data = {...new RfqFormDTO()};

  setRef = elm => this.formElm = elm;

  scrollToEnd = () => this.formElm && this.formElm.scrollToBottom(300);

  autoPackingLocationValue = () => {
    if (!this.data.packing) return;
    this.data.packing.location = this.hasDoorToService && !(this.data.packing || {}).locationOverride
      ? this.doorServicePickupAddress
      : this.data.packing.location;
  };

  getShippingUnits = async () => getUnits().then(units => this.shippingUnits = units);

  onServiceDateChange = (event: any) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    const name = event.target.name;
    const { value } = event.detail;
    if (!value || !name) return;
    // const timestamp = new Date(value).setHours(0, 0, 0, 0);
    const timestamp = new Date(value).getTime();
    if (timestamp === this.data[name]) return;
    return this.data[name] = timestamp;
  };

  onArrivalDatePopup = (event: any) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    if (this.arrivalDatePopupShowed) return this.arrivalDateSelectorOpen = true;
    this.arrivalDatePopupShowed = true;
    return ui.alert({
      header: UIText.rfqForm.arrivalDate,
      message: UIText.rfqForm.arrivalDatePopup,
      buttons: [{
        text: UIText.generalConfirm,
        handler: runInAsync(() => this.arrivalDateSelectorOpen = true, 100)
      }],
      backdropDismiss: false,
    });
  };

  onPrimaryModeChange = (event: any, mode: RfqFormDTO["mode"]) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    if (this.data.mode === mode) return;
    this.data.mode = mode;
  };

  onAlternativeModesChange = (event: any) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    const name = event.target.name;
    if (!name) return;
    if (this.data.mode === name) return;
    const index = this.data.altModes.indexOf(name);
    if (index > -1) return this.data.altModes.splice(index, 1);
    return this.data.altModes.push(name);
  };

  onMultimodalChange = (event: any) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    if (this.data.multimodal) return this.data.multimodal = false;
    return ui.alert({
      header: UIText.rfqForm.multimodal,
      message: UIText.rfqForm.multimodalPopup,
      buttons: [
        {
          text: UIText.generalNo,
          role: "cancel",
        },
        {
          text: UIText.generalYes,
          handler: () => {
            this.data.multimodal = true;
            this.data.altModes = undefined;
          }
        }
      ]
    });
  };

  onStringFieldChange = (event: any) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    const name = event.target.name;
    if (!name) return;
    const realValue = getEventRealValue(event);
    let property = this.data;
    const nameFrag = name.split(".");
    if (nameFrag.length > 1) {
      property = this.data[nameFrag[0]];
      if (!property) return;
    }
    if (name === "inspection._otherChecked" &&
      !realValue &&
      (this.data.inspection && this.data.inspection.other)) {
      this.data.inspection.other = undefined;
    }
    if (property[nameFrag[1] || name] === realValue) return;
    return property[nameFrag[1] || name] = realValue;
  };

  onCheckboxFieldChange = (event: any, isInland?: boolean) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    const name = event.target.name;
    const { checked } = event.detail;
    if (!!this.data[name] === checked) return;
    if (isInland) {
      if (!checked) return;
      this.data.portToPort
        = this.data.doorToPort
        = this.data.portToDoor
        = this.data.doorToDoor
        = undefined;
    }
    this.data[name] = checked
      ? name.match(/portToDoor|doorToPort|doorToDoor/g)
        ? {}
        : name.match(/altModes/g)
          ? []
          : true
      : undefined;
    // if (this.freights.indexOf(freight) === this.freights.length - 1) {
    //   return setTimeout(this.scrollToEnd, 500);
    // }
  };
  
  onAddItem = (type: RfqFormItemDTO["type"]) => {
    if (this.props.readonly) return;
    if (!type) return;
    if (!this.data[`_${this.data.mode}_items`]) this.data[`_${this.data.mode}_items`] = [];
    const last = this.items[this.items.length - 1] || {} as RfqFormItemDTO;
    this.items.push({
      key: (last.key || 0) + 1,
      type,
      notes: "",
      measurementMode: "dimension",
      weightDispUnit: last.weightDispUnit || defaultWeightUnit,
      dimensionDispUnit: last.dimensionDispUnit || defaultDimensionUnit
    });
  };

  onRemoveItem = (
    event: any,
    itemKey: RfqFormItemDTO["key"]
  ) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    const freightItem = this.items.find(item => item.key === itemKey);
    if (!freightItem) return;
    return (this.items as IObservableArray<RfqFormItemDTO>).remove(freightItem);
  };

  onItemTypeChange = (
    type: RfqFormItemDTO["type"],
    itemKey: RfqFormItemDTO["key"]
  ) => {
    if (this.props.readonly) return;
    const freightItem = this.items.find(item => item.key === itemKey);
    if (!freightItem) return;
    if (freightItem.type === type) return;
    return freightItem.type = type;
  };

  onItemWeightChange = (
    event: any,
    itemKey: RfqFormItemDTO["key"]
  ) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    const freightItem = this.items.find(item => item.key === itemKey);
    if (!freightItem) return;
    const { value } = event.target;
    const realValue = rfqRoundWeight(value || 0, freightItem.weightDispUnit, defaultWeightUnit);
    if (freightItem.weight === realValue) return;
    return freightItem.weight = realValue;
  };

  onItemDimensionChange = (
    event: any,
    itemKey: RfqFormItemDTO["key"]
  ) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    const freightItem = this.items.find(item => item.key === itemKey);
    if (!freightItem) return;
    const name = event.target.name;
    const { value } = event.target;
    const realValue = rfqRoundDimension(value || 0, freightItem.dimensionDispUnit, defaultDimensionUnit);
    if (freightItem[name] === realValue) return;
    return freightItem[name] = realValue;
  };

  onItemMeasurementModeChange = (itemKey: RfqFormItemDTO["key"]) => {
    if (this.props.readonly) return;
    const freightItem = this.items.find(item => item.key === itemKey);
    if (!freightItem) return;
    freightItem.measurementMode = freightItem.measurementMode === "volume" ? "dimension" : "volume";
    if (freightItem.measurementMode === "dimension") {
      freightItem.volume = undefined;
    } else {
      if (!freightItem.volumeDispUnit) freightItem.volumeDispUnit = "CBM";
      // freightItem.type.match(/pieces|lcl|ltl/g) ? volumeCBMOptions[0] : "";
      freightItem.length = freightItem.width = freightItem.height = undefined;
    }
  };

  onItemUnitChange = (
    unit: ElementType<typeof weightOptions> | ElementType<typeof dimensionOptions>,
    type: "weight" | "dimension",
    itemKey: RfqFormItemDTO["key"],
    applyAll?: boolean
  ) => {
    const freightItem = this.items.find(item => item.key === itemKey);
    if (!freightItem) return;
    if (applyAll) {
      for (const item of this.items) item[`${type}DispUnit`] = unit;
      return;
    }
    return freightItem[`${type}DispUnit`] = unit;
  };

  onItemFieldChange = (
    event: any,
    itemKey: RfqFormItemDTO["key"]
  ) => {
    preventDefaultStopProp(event);
    if (this.props.readonly) return;
    const freightItem = this.items.find(item => item.key === itemKey);
    if (!freightItem) return;
    const name = event.target.name;
    const realValue = getEventRealValue(event);
    if (realValue === freightItem[name]) return;
    return freightItem[name] = realValue;
  };

  onItemReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    if (this.props.readonly) return false;
    const { complete } = event.detail;
    return this.data[`_${this.data.mode}_items`] = complete(this.data[`_${this.data.mode}_items`]);
  };

  validate = (): boolean => {
    let pass = true;
    let itemsPass = true;
    for (const name of Object.keys(this.errors)) {
      if (name === "shipmentItems") {
        this.errors[name] = this.items.length === 0;
        if (this.errors[name]) pass = false;
        continue;
      }
      let property = this.data;
      const nameFrag = name.split(".");
      if (nameFrag.length > 1) {
        property = this.data[nameFrag[0]];
        if (!property) continue;
      }
      if (!property[nameFrag[1] || name]) {
        this.errors[name] = true;
        pass = false;
      }
    }
    if (this.items.length > 0) itemsPass = this.validateItems();
    return pass && itemsPass;
  };

  validateItems = (): boolean => {
    let pass = true;
    if (this.items.length === 0) return pass;
    for (const property of this.itemErrors) {
      for (const item of this.items) {
        if (!this.itemErrorItems[item.key]) this.itemErrorItems[item.key] = {};
        const nameFrag = property.split(".");
        if (nameFrag.length > 1) {
          const type = nameFrag[0];
          if (type !== item.type) continue;
        }
        if (!item[nameFrag[1] || property]) {
          this.itemErrorItems[item.key][nameFrag[1] || property] = true;
          pass = false;
        }
        if (item.measurementMode && item.type !== "fcl" && item.type !== "ftl") {
          if (item.measurementMode === "dimension") {
            if (item.length && item.width && item.height) continue;
            this.itemErrorItems[item.key]["dimension"] = true;
            pass = false;
          }
          if (item.measurementMode === "volume") {
            if (item.volume) continue;
            this.itemErrorItems[item.key]["volume"] = true;
            pass = false;
          }
        }
      }
    }
    return pass;
  };

  clearErrors = () => {
    Object.keys(this.errors).forEach(key => this.errors[key] = false);
    this.items.forEach(item => this.itemErrorItems[item.key] = {});
  };

  render() {
    const { className, readonly, onRefresh, onIonScroll } = this.props;
    const wrapCheckboxEvent = (event, name, checked) => {
      event.target = { name } as unknown as EventTarget;
      event.detail = {
        checked: !checked,
      };
      return this.onCheckboxFieldChange(event, true);
    };
    const wrapContainerChangeEvent = (type, itemKey) => {
      const event = {
        target: { name: "containerType" },
        detail: { value: type }
      } as unknown as CustomEvent;
      return this.onItemFieldChange(event, itemKey);
    };
    const wrapVolumeUnitChangeEvent = (unit, itemKey) => {
      const event = {
        target: { name: "volumeDispUnit" },
        detail: { value: unit }
      } as unknown as CustomEvent;
      return this.onItemFieldChange(event, itemKey);
    };
    const processDateChangeEvent = (event: any) => {
      const { value } = event.detail;
      if (!value) return event;
      event.detail.value = new Date(value).setHours(0, 0, 0, 0);
      return event;
    };
      
    return (
      <IonContent
        ref={this.setRef}
        scrollEvents={!!onIonScroll}
        onIonScroll={onIonScroll}
        className={`rfqForm max fullBackground ${className || ""} ${readonly ? "readonly" : ""}`}
      >
        {onRefresh && <Refresher customAppearance appearTop color="primary" onRefresh={onRefresh} isMobile={ui.isMobile} />}
        <IonList style={readonly && { paddingBottom: 0 }}>
          <div className="flex ion-justify-content-between">
            <IonListHeader className={`textBold ${ui.isMobile ? "font-xs" : "font-s"}`}>
              {readonly ? UIText.rfqForm.headingReadonly : UIText.rfqForm.heading}
            </IonListHeader>
          </div>
          
          <div className="goods">
            <IonItem>
              <IonLabel position="floating" color={!!this.errors.goods && "danger"}>
                {Form.mandatoryFieldLabel(UIText.rfqForm.goods)}
              </IonLabel>
              <TwoStageInput
                name="goods"
                endIcon={ellipsisHorizontal}
                readonly={readonly}
                placeholder={UIText.rfqForm.describeGoods}
                value={this.data.goods}
                onIonChange={this.onStringFieldChange}
              />
            </IonItem>
            <IonItem lines="full" className="ion-no-padding ion-no-margin">
              <IonLabel position="stacked">
                {UIText.rfqForm.dangerousGoods}
              </IonLabel>
              <IonCheckboxWithReadonly
                name="dangerous"
                readonly={readonly}
                checked={!!this.data.dangerous}
                onIonChange={this.onCheckboxFieldChange}
              />
            </IonItem>
          </div>

          <IonItem lines="full">
            <IonLabel position="stacked">
              {UIText.rfqForm.transportation}
            </IonLabel>
            <IonButtons className="buttonRow checkboxRow ion-padding-top">
              <IonItem
                button
                key="air"
                lines="none"
                className="checkboxItem"
                onClick={e => this.onPrimaryModeChange(e, "air")}
              >
                <IonIcon
                  icon={this.data.mode === "air" ? radioButtonOnOutline : radioButtonOffOutline}
                  color={this.data.mode === "air" && "primary"}
                />
                <IonLabel className="ion-padding-horizontal" color={this.data.mode === "air" && "primary"}>
                  {UIText.rfqForm.air}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                key="sea"
                lines="none"
                className="checkboxItem"
                onClick={e => this.onPrimaryModeChange(e, "sea")}
              >
                <IonIcon
                  icon={this.data.mode === "sea" ? radioButtonOnOutline : radioButtonOffOutline}
                  color={this.data.mode === "sea" && "primary"}
                />
                <IonLabel className="ion-padding-horizontal" color={this.data.mode === "sea" && "primary"}>
                  {UIText.rfqForm.sea}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                key="rail"
                lines="none"
                className="checkboxItem"
                onClick={e => this.onPrimaryModeChange(e, "rail")}
              >
                <IonIcon
                  icon={this.data.mode === "rail" ? radioButtonOnOutline : radioButtonOffOutline}
                  color={this.data.mode === "rail" && "primary"}
                />
                <IonLabel className="ion-padding-horizontal" color={this.data.mode === "rail" && "primary"}>
                  {UIText.rfqForm.rail}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                key="road"
                lines="none"
                className="checkboxItem"
                onClick={e => this.onPrimaryModeChange(e, "road")}
              >
                <IonIcon
                  icon={this.data.mode === "road" ? radioButtonOnOutline : radioButtonOffOutline}
                  color={this.data.mode === "road" && "primary"}
                />
                <IonLabel className="ion-padding-horizontal" color={this.data.mode === "road" && "primary"}>
                  {UIText.rfqForm.road}
                </IonLabel>
              </IonItem>
            </IonButtons>
          </IonItem>

          {this.data.mode && !this.data.multimodal && (
            <IonItem lines={!!this.data.altModes ? "none" : "full"} className="checkboxItem">
              <IonCheckboxWithReadonly
                name="altModes"
                className="ion-margin-start"
                readonly={readonly}
                checked={readonly ? !isEmpty(this.data.altModes) : !!this.data.altModes}
                onIonChange={this.onCheckboxFieldChange}
              />
                <IonLabel
                  className="ion-text-wrap font-xs ion-padding-horizontal"
                  color={!!this.data.altModes && "primary"}
                >
                  {UIText.rfqForm.altModes}
                </IonLabel>
            </IonItem>
          )}

          {((!readonly && !!this.data.altModes) || (!this.data.multimodal && !isEmpty(this.data.altModes))) && (
            <IonItem lines="full">
              <IonLabel position="stacked">
                {UIText.rfqForm.altModeSpecify}
              </IonLabel>
              <IonButtons className="buttonRow checkboxRow ion-padding-top">
                {this.data.mode !== "air" && (!readonly || this.data.altModes.includes("air")) && (
                  <IonItem lines="none" className="checkboxItem" key="air">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="air"
                      readonly={readonly}
                      checked={this.data.altModes.includes("air")}
                      onIonChange={this.onAlternativeModesChange}
                    />
                      <IonLabel className="ion-padding-horizontal" color={this.data.altModes.includes("air") && "primary"}>
                        {UIText.rfqForm.air}
                      </IonLabel>
                  </IonItem>
                )}
                {this.data.mode !== "sea" && (!readonly || this.data.altModes.includes("sea")) && (
                  <IonItem lines="none" className="checkboxItem" key="sea">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="sea"
                      readonly={readonly}
                      checked={this.data.altModes.includes("sea")}
                      onIonChange={this.onAlternativeModesChange}
                    />
                      <IonLabel className="ion-padding-horizontal" color={this.data.altModes.includes("sea") && "primary"}>
                        {UIText.rfqForm.sea}
                      </IonLabel>
                  </IonItem>
                )}
                {this.data.mode !== "rail" && (!readonly || this.data.altModes.includes("rail")) && (
                  <IonItem lines="none" className="checkboxItem" key="rail">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="rail"
                      readonly={readonly}
                      checked={this.data.altModes.includes("rail")}
                      onIonChange={this.onAlternativeModesChange}
                    />
                      <IonLabel className="ion-padding-horizontal" color={this.data.altModes.includes("rail") && "primary"}>
                        {UIText.rfqForm.rail}
                      </IonLabel>
                  </IonItem>
                )}
                {this.data.mode !== "road" && (!readonly || this.data.altModes.includes("road")) && (
                  <IonItem lines="none" className="checkboxItem" key="road">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="road"
                      readonly={readonly}
                      checked={this.data.altModes.includes("road")}
                      onIonChange={this.onAlternativeModesChange}
                    />
                      <IonLabel className="ion-padding-horizontal" color={this.data.altModes.includes("road") && "primary"}>
                        {UIText.rfqForm.road}
                      </IonLabel>
                  </IonItem>
                )}
              </IonButtons>
            </IonItem>
          )}

          {this.data.mode && (
            <IonItem lines="full" className="checkboxItem" onClick={this.onMultimodalChange}>
              <IonCheckboxWithReadonly
                name="multimodal"
                className="noEvents ion-margin-start"
                readonly={readonly}
                checked={!!this.data.multimodal}
              />
                <IonLabel
                  className="ion-text-wrap font-xs ion-padding-horizontal"
                  color={!!this.data.multimodal && "primary"}
                >
                  {UIText.rfqForm.multimodalQuestion}
                </IonLabel>
            </IonItem>
          )}

          <div className="half">
            <MaterialDateTimeItem
              useMuiOnly
              type="datetime"
              lines="full"
              name="cargoReadyDate"
              color={this.errors.cargoReadyDate && "danger"}
              label={Form.mandatoryFieldLabel(UIText.rfqForm.cargoReadyDate)}
              readonly={readonly}
              min={new Date().toISOString()}
              max={this.data.cargoArrivalDate ? new Date(this.data.cargoArrivalDate).toISOString() : undefined}
              placeholder={readonly ? "—" : UIText.rfqForm.dateTimePlaceholder}
              displayFormat="MMM DD, YYYY h:mm A"
              value={this.data.cargoReadyDate && new Date(this.data.cargoReadyDate).toISOString()}
              onIonChange={this.onServiceDateChange}
            />
            <div className="relative">
              <MaterialDateTimeItem
                useMuiOnly
                lines="full"
                name="cargoArrivalDate"
                label={UIText.rfqForm.arrivalDate}
                readonly={readonly}
                min={
                  this.data.cargoReadyDate
                    ? new Date(this.data.cargoReadyDate).toISOString()
                    : new Date().toISOString()
                }
                placeholder={readonly ? "—" : UIText.rfqForm.datePlaceholder}
                displayFormat="MMM DD, YYYY"
                value={this.data.cargoArrivalDate && new Date(this.data.cargoArrivalDate).toISOString()}
                isOpen={this.arrivalDateSelectorOpen}
                onClose={() => this.arrivalDateSelectorOpen = false}
                onClick={this.onArrivalDatePopup}
                onIonChange={this.onServiceDateChange}
              />
              {!readonly && <HelpTip
                className="helpTip absolute"
                help={{
                  header: UIText.rfqForm.arrivalDate,
                  message: UIText.rfqForm.arrivalDatePopup
                }}
              />}
            </div>
          </div>

           <IonItem lines="none">
              <IonLabel position="floating">
                {UIText.rfqForm.otherInfo}
              </IonLabel>
              <TwoStageInput
                name="otherInformation"
                className={ui.isMobile ? "editorSmallPlaceholder" : ""}
                readonly={readonly}
                heading={UIText.rfqForm.otherInfo}
                placeholder={readonly ? "—" : UIText.rfqForm.otherInfoPlaceholder}
                value={this.data.otherInformation}
                onIonChange={this.onStringFieldChange}
                endIcon={ellipsisHorizontal}
              />
            </IonItem>
            <IonItem 
              className={this.items.length > 0 && !ui.isMobile ? "topBorder ion-margin-top" : ""} 
              lines={!!this.data.other ? "none": "full"}
            >
              <IonLabel position="stacked">
                {UIText.rfqForm.goodsAttributes}
              </IonLabel>
              <IonButtons className="buttonRow checkboxRow ion-padding-top">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    {UIText.rfqForm.perishableGoods}
                  </IonLabel>
                  <IonCheckboxWithReadonly
                    name="perishable"
                    readonly={readonly}
                    checked={!!this.data.perishable}
                    onIonChange={this.onCheckboxFieldChange}
                  />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    {UIText.rfqForm.liveAnimals}
                  </IonLabel>
                  <IonCheckboxWithReadonly
                    name="liveAnimals"
                    readonly={readonly}
                    checked={!!this.data.liveAnimals}
                    onIonChange={this.onCheckboxFieldChange}
                  />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    {UIText.rfqForm.highValueMerchandise}
                  </IonLabel>
                  <IonCheckboxWithReadonly
                    name="highValueMerchandise"
                    readonly={readonly}
                    checked={!!this.data.highValueMerchandise}
                    onIonChange={this.onCheckboxFieldChange}
                  />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    {UIText.rfqForm.otherCargo}
                  </IonLabel>
                  <IonCheckboxWithReadonly
                    name="other"
                    readonly={readonly}
                    checked={!!this.data.other}
                    onIonChange={this.onCheckboxFieldChange}
                  />
                </IonItem>
            </IonButtons>
          </IonItem>
          {!!this.data.other && <IonItem>
            <IonLabel position="stacked">
              {UIText.rfqForm.otherAttributeNotes}
            </IonLabel>
            <TwoStageInput
              name="otherAttributeNotes"
              className={ui.isMobile ? "editorSmallPlaceholder" : ""}
              readonly={readonly}
              heading={UIText.rfqForm.otherAttributeNotes}
              placeholder={readonly ? "—" : UIText.rfqForm.otherAttributeNotesPlaceholder}
              value={this.data.otherAttributeNotes}
              maxlength={50}
              onIonChange={this.onStringFieldChange}
              endIcon={ellipsisHorizontal}
            />
          </IonItem>}
          <IonItem>
            <IonLabel position="floating">
              {UIText.rfqForm.shippersReference}
            </IonLabel>
            <TwoStageInput
              name="reference"
              className={ui.isMobile ? "editorSmallPlaceholder" : ""}
              readonly={readonly}
              heading={UIText.rfqForm.shippersReference}
              placeholder={readonly ? "—" : UIText.rfqForm.shippersReferencePlaceholder}
              value={this.data.reference}
              maxlength={50}
              onIonChange={this.onStringFieldChange}
              endIcon={ellipsisHorizontal}
            />
          </IonItem>

          <div className="half">
            <IonItem>
              <IonLabel position="floating" color={this.errors.pol && "danger"}>
                {Form.mandatoryFieldLabel(this.data.mode.match(/air|road/g) ? UIText.rfqForm.origin : UIText.rfqForm.pol)}
              </IonLabel>
              <PlacesAutocomplete
                readonly={readonly}
                name="pol"
                biasCenter
                autocompleteOptions={{
                  types: ["(cities)"]
                }}
                heading={this.data.mode.match(/air|road/g) ? UIText.rfqForm.origin : UIText.rfqForm.pol}
                value={this.data.pol}
                limitTo="city"
                placeholder={UIText.rfqForm.cityProvStateCountry}
                onPositionChange={this.onStringFieldChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating" color={this.errors.pod && "danger"}>
                {Form.mandatoryFieldLabel(this.data.mode.match(/air|road/g) ? UIText.rfqForm.destination : UIText.rfqForm.pod)}
              </IonLabel>
              <PlacesAutocomplete
                readonly={readonly}
                name="pod"
                autocompleteOptions={{
                  types: ["(cities)"]
                }}
                heading={this.data.mode.match(/air|road/g) ? UIText.rfqForm.destination : UIText.rfqForm.pod}
                value={this.data.pod}
                limitTo="city"
                placeholder={UIText.rfqForm.cityProvStateCountry}
                onPositionChange={this.onStringFieldChange}
              />
            </IonItem>
          </div>

          <IonItem lines="full">
            <IonLabel position="stacked">
              {UIText.rfqForm.freightServicesHeading}
            </IonLabel>
            <IonButtons className="buttonRow checkboxRow ion-padding-top">
              <IonItem
                button
                key="portToPort"
                lines="none"
                className="checkboxItem"
                onClick={e => !readonly && wrapCheckboxEvent(e, "portToPort", !!this.data.portToPort)}
              >
                <IonIcon
                  icon={this.data.portToPort ? radioButtonOnOutline : radioButtonOffOutline}
                  color={this.data.portToPort && "primary"}
                />
                <IonLabel className="ion-padding-horizontal" color={this.data.portToPort && "primary"}>
                  {UIText.rfqForm.portToPort}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                key="doorToPort"
                lines="none"
                className="checkboxItem"
                onClick={e => !readonly && wrapCheckboxEvent(e, "doorToPort", !!this.data.doorToPort)}
              >
                <IonIcon
                  icon={this.data.doorToPort ? radioButtonOnOutline : radioButtonOffOutline}
                  color={this.data.doorToPort && "primary"}
                />
                <IonLabel className="ion-padding-horizontal" color={this.data.doorToPort && "primary"}>
                  {UIText.rfqForm.doorToPort}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                key="portToDoor"
                lines="none"
                className="checkboxItem"
                onClick={e => !readonly && wrapCheckboxEvent(e, "portToDoor", !!this.data.portToDoor)}
              >
                <IonIcon
                  icon={this.data.portToDoor ? radioButtonOnOutline : radioButtonOffOutline}
                  color={this.data.portToDoor && "primary"}
                />
                <IonLabel className="ion-padding-horizontal" color={this.data.portToDoor && "primary"}>
                  {UIText.rfqForm.portToDoor}
                </IonLabel>
              </IonItem>
              <IonItem
                button
                key="doorToDoor"
                lines="none"
                className="checkboxItem"
                onClick={e => !readonly && wrapCheckboxEvent(e, "doorToDoor", !!this.data.doorToDoor)}
              >
                <IonIcon
                  icon={this.data.doorToDoor ? radioButtonOnOutline : radioButtonOffOutline}
                  color={this.data.doorToDoor && "primary"}
                />
                <IonLabel className="ion-padding-horizontal" color={this.data.doorToDoor && "primary"}>
                  {UIText.rfqForm.doorToDoor}
                </IonLabel>
              </IonItem>
            </IonButtons>
          </IonItem>

          {this.data.doorToPort && <IonList className="ion-no-padding">
            <IonListHeader className="textBold">
              {UIText.rfqForm.doorToPort}
            </IonListHeader>
            <IonItem lines="full">
              <IonLabel position="floating" color={this.errors["doorToPort.pickUpAddress"] && "danger"}>
                {Form.mandatoryFieldLabel(UIText.rfqForm.pickUpAddress)}
              </IonLabel>
              <PlacesAutocomplete
                readonly={readonly}
                name="doorToPort.pickUpAddress"
                biasCenter
                heading={UIText.rfqForm.pickUpAddress}
                value={this.data.doorToPort.pickUpAddress}
                placeholder={UIText.rfqForm.addrCityProvStateCountry}
                onPositionChange={this.onStringFieldChange}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="floating">
                {UIText.rfqForm.customsInformation}
              </IonLabel>
              <IonInput
                readonly={readonly}
                name="doorToPort.customsInformation"
                value={this.data.doorToPort.customsInformation}
                onIonChange={this.onStringFieldChange}
              />
            </IonItem>
          </IonList>}
          {this.data.portToDoor && <IonList className="ion-no-padding">
            <IonListHeader className="textBold">
              {UIText.rfqForm.portToDoor}
            </IonListHeader>
            <IonItem lines="full">
              <IonLabel position="floating" color={this.errors["portToDoor.deliveryAddress"] && "danger"}>
                {Form.mandatoryFieldLabel(UIText.rfqForm.deliveryAddress)}
              </IonLabel>
              <PlacesAutocomplete
                readonly={readonly}
                name="portToDoor.deliveryAddress"
                heading={UIText.rfqForm.deliveryAddress}
                value={this.data.portToDoor.deliveryAddress}
                placeholder={UIText.rfqForm.addrCityProvStateCountry}
                onPositionChange={this.onStringFieldChange}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="floating">
                {UIText.rfqForm.customsInformation}
              </IonLabel>
              <IonInput
                readonly={readonly}
                name="portToDoor.customsInformation"
                value={this.data.portToDoor.customsInformation}
                onIonChange={this.onStringFieldChange}
              />
            </IonItem>
          </IonList>}
          {this.data.doorToDoor && <IonList className="ion-no-padding">
            <IonListHeader className="textBold">
              {UIText.rfqForm.doorToDoor}
            </IonListHeader>
            <IonItem lines="full">
              <IonLabel position="floating" color={this.errors["doorToDoor.pickUpAddress"] && "danger"}>
                {Form.mandatoryFieldLabel(UIText.rfqForm.pickUpAddress)}
              </IonLabel>
              <PlacesAutocomplete
                readonly={readonly}
                name="doorToDoor.pickUpAddress"
                biasCenter
                heading={UIText.rfqForm.pickUpAddress}
                value={this.data.doorToDoor.pickUpAddress}
                placeholder={UIText.rfqForm.addrCityProvStateCountry}
                onPositionChange={this.onStringFieldChange}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="floating" color={this.errors["doorToDoor.deliveryAddress"] && "danger"}>
                {Form.mandatoryFieldLabel(UIText.rfqForm.deliveryAddress)}
              </IonLabel>
              <PlacesAutocomplete
                readonly={readonly}
                name="doorToDoor.deliveryAddress"
                heading={UIText.rfqForm.deliveryAddress}
                value={this.data.doorToDoor.deliveryAddress}
                placeholder={UIText.rfqForm.addrCityProvStateCountry}
                onPositionChange={this.onStringFieldChange}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel position="floating">
                {UIText.rfqForm.customsInformation}
              </IonLabel>
              <IonInput
                readonly={readonly}
                name="doorToDoor.customsInformation"
                value={this.data.doorToDoor.customsInformation}
                onIonChange={this.onStringFieldChange}
              />
            </IonItem>
          </IonList>}

          {readonly && !isEmpty(this.items) && <IonListHeader className="textBold">
            {UIText.rfqForm.shipmentItemsReadonly}
          </IonListHeader>}

          {!readonly && <IonItem lines="full">
            <IonLabel position="stacked" color={this.errors.shipmentItems && "danger"} className="ion-text-wrap">
              {Form.mandatoryFieldLabel(this.errors.shipmentItems ? UIText.rfqForm.shipmentItemsError : UIText.rfqForm.shipmentItems)}
            </IonLabel>
            <IonButtons className="buttonRow ion-padding-vertical">
              {this.data.mode === "air" && <>
                <IonButton onClick={() => this.onAddItem("pieces")}>
                  <MdIcon icon={mdiPlus}/>
                  <span>{UIText.rfqForm.pieces}</span>
                </IonButton>
              </>}
              {(this.data.mode === "sea" || this.data.mode === "rail") && <>
                <IonButton onClick={() => this.onAddItem("fcl")}>
                  <MdIcon icon={mdiPlus}/>
                  <span>{UIText.rfqForm.fcl}</span>
                </IonButton>
                <IonButton onClick={() => this.onAddItem("lcl")}>
                  <MdIcon icon={mdiPlus}/>
                  <span>{UIText.rfqForm.lcl}</span>
                </IonButton>
                {/*<IonButton onClick={() => this.onAddItem("other")}>*/}
                {/*  <MdIcon icon={mdiPlus}/>*/}
                {/*  <span>{UIText.rfqForm.other}</span>*/}
                {/*</IonButton>*/}
              </>}
              {this.data.mode === "road" && <>
                <IonButton onClick={() => this.onAddItem("ftl")}>
                  <MdIcon icon={mdiPlus}/>
                  <span>{UIText.rfqForm.ftl}</span>
                </IonButton>
                <IonButton onClick={() => this.onAddItem("ltl")}>
                  <MdIcon icon={mdiPlus}/>
                  <span>{UIText.rfqForm.ltl}</span>
                </IonButton>
              </>}
            </IonButtons>
          </IonItem>}

          <IonReorderGroup disabled={false} onIonItemReorder={this.onItemReorder}>
            {this.items.map((item, i) => {
              const itemKey = item.key || i;
              return <RfqFormFreightItem
                readonly={readonly}
                error={this.itemErrorItems[itemKey] || {}}
                mode={this.data.mode}
                key={itemKey || i}
                item={item}
                index={i}
                shippingUnits={this.shippingUnits}
                onRemoveItem={e => this.onRemoveItem(e, itemKey)}
                onTypeChange={type => this.onItemTypeChange(type, itemKey)}
                onNotesChange={e => this.onItemFieldChange(e, itemKey)}
                onWeightChange={e => this.onItemWeightChange(e, itemKey)}
                onDimensionChange={e => this.onItemDimensionChange(e, itemKey)}
                onVolumeChange={e => this.onItemFieldChange(e, itemKey)}
                onMeasurementModeChange={() => this.onItemMeasurementModeChange(itemKey)}
                onVolumeUnitChange={unit => wrapVolumeUnitChangeEvent(unit, itemKey)}
                onUnitChange={(unit, type, all) => this.onItemUnitChange(unit, type, itemKey, all)}
                onContainerChange={type => wrapContainerChangeEvent(type, itemKey)}
                onPiecesChange={e => this.onItemFieldChange(e, itemKey)}
                onSocChange={e => this.onItemFieldChange(e, itemKey)}
                // onAddInfoChange={e => this.onItemFieldChange(e, itemKey)}
              />
            })}
          </IonReorderGroup>

          {(!readonly || this.data.packing || this.data.storage || this.data.inspection) && (
            <IonItem className={this.items.length > 0 && !ui.isMobile ? "topBorder ion-margin-top" : ""} lines="full">
              <IonLabel position="stacked">
                {readonly ? UIText.rfqForm.additionalServiceReadonly : UIText.rfqForm.additionalService}
              </IonLabel>
              <IonButtons className="buttonRow checkboxRow ion-padding-top">
                {(!readonly || this.data.packing) && (
                  <IonItem lines="none" className="checkboxItem" key="packing">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="packing"
                      readonly={readonly}
                      checked={!!this.data.packing}
                      onIonChange={this.onCheckboxFieldChange}
                    />
                    {(!readonly || !!this.data.packing) && (
                      <IonLabel className="ion-padding-horizontal" color={this.data.packing && "primary"}>
                        {UIText.rfqForm.packing}
                      </IonLabel>
                    )}
                  </IonItem>
                )}
                {(!readonly || this.data.storage) && (
                  <IonItem lines="none" className="checkboxItem" key="storage">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="storage"
                      readonly={readonly}
                      checked={!!this.data.storage}
                      onIonChange={this.onCheckboxFieldChange}
                    />
                    {(!readonly || !!this.data.storage) && (
                      <IonLabel className="ion-padding-horizontal" color={this.data.storage && "primary"}>
                        {UIText.rfqForm.storage}
                      </IonLabel>
                    )}
                  </IonItem>
                )}
                {(!readonly || this.data.inspection) && (
                  <IonItem lines="none" className="checkboxItem" key="inspection">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="inspection"
                      readonly={readonly}
                      checked={!!this.data.inspection}
                      onIonChange={this.onCheckboxFieldChange}
                    />
                    {(!readonly || !!this.data.inspection) && (
                      <IonLabel className="ion-padding-horizontal" color={this.data.inspection && "primary"}>
                        {UIText.rfqForm.inspection}
                      </IonLabel>
                    )}
                  </IonItem>
                )}
              </IonButtons>
            </IonItem>
          )}

          {this.data.packing && (!readonly || !isEmpty(this.data.packing)) && <IonList className="ion-no-padding">
            <IonListHeader className="textBold">
              {UIText.rfqForm.packing}
              {this.hasDoorToService && (
                <IonItem lines="none" className="font-xs ion-margin-start">
                  <IonCheckboxWithReadonly
                    readonly={readonly}
                    checked={!!this.data.packing.locationOverride}
                    onIonChange={() => this.data.packing.locationOverride = !this.data.packing.locationOverride}
                  />
                  <IonLabel
                    className="ion-padding-horizontal ion-text-wrap"
                    color={this.data.packing.locationOverride && "primary"}
                  >
                    {UIText.rfqForm.packingAddressOverride}
                  </IonLabel>
                </IonItem>
              )}
            </IonListHeader>
            <div className="half">
              <IonItem lines="full">
                <IonLabel position="floating">
                  {UIText.rfqForm.address}
                </IonLabel>
                <PlacesAutocomplete
                  readonly={readonly || (this.hasDoorToService && !this.data.packing.locationOverride)}
                  name="packing.location"
                  heading={UIText.rfqForm.address}
                  placeholder={UIText.rfqForm.addrCityProvStateCountry}
                  value={this.data.packing.location}
                  onPositionChange={this.onStringFieldChange}
                />
              </IonItem>
              <MaterialDateTimeItem
                lines="full"
                label={UIText.rfqForm.date}
                readonly={readonly}
                name="packing.date"
                min={this.data.cargoReadyDate ? new Date(this.data.cargoReadyDate).toISOString() : new Date().toISOString()}
                max={this.data.cargoArrivalDate ? new Date(this.data.cargoArrivalDate).toISOString() : undefined}
                placeholder={readonly ? "—" : UIText.rfqForm.datePlaceholder}
                displayFormat="MMM DD, YYYY"
                value={this.data.packing.date && new Date(this.data.packing.date).toISOString()}
                onIonChange={e => this.onStringFieldChange(processDateChangeEvent(e))}
              />
              <IonItem lines="full">
                <IonLabel position="floating">
                  {UIText.rfqForm.notes}
                </IonLabel>
                <TwoStageInput
                  readonly={readonly}
                  name="packing.notes"
                  heading={`${UIText.rfqForm.packing} ${UIText.rfqForm.notes}`}
                  value={this.data.packing.notes}
                  onIonChange={this.onStringFieldChange}
                  endIcon={ellipsisHorizontal}
                />
              </IonItem>
            </div>
          </IonList>}
          {this.data.storage && (!readonly || !isEmpty(this.data.storage)) && <IonList className="ion-no-padding">
            <IonListHeader className="textBold">
              {UIText.rfqForm.storage}
            </IonListHeader>
            <div className="half">
              <IonItem lines="full">
                <IonLabel position="floating">
                  {UIText.rfqForm.location}
                </IonLabel>
                <PlacesAutocomplete
                  readonly={readonly}
                  name="storage.location"
                  heading={UIText.rfqForm.location}
                  placeholder={UIText.rfqForm.cityProvStateCountry}
                  value={this.data.storage.location}
                  onPositionChange={this.onStringFieldChange}
                />
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">
                  {UIText.rfqForm.duration}
                </IonLabel>
                <IonInput
                  readonly={readonly}
                  name="storage.duration"
                  inputMode="numeric"
                  type="number"
                  min="0"
                  placeholder="0"
                  value={this.data.storage.duration}
                  onIonChange={this.onStringFieldChange}
                />
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">
                  {UIText.rfqForm.notes}
                </IonLabel>
                <TwoStageInput
                  readonly={readonly}
                  name="storage.notes"
                  heading={`${UIText.rfqForm.storage} ${UIText.rfqForm.notes}`}
                  value={this.data.storage.notes}
                  onIonChange={this.onStringFieldChange}
                  endIcon={ellipsisHorizontal}
                />
              </IonItem>
            </div>
          </IonList>}
          {this.data.inspection && (!readonly || !isEmpty(this.data.inspection)) && <IonList className="ion-no-padding">
            <IonListHeader className="textBold">
              {UIText.rfqForm.inspection}
            </IonListHeader>
            <IonItem lines="full" className="ion-no-padding">
              <IonButtons className="buttonRow checkboxRow ion-padding-start">
                {(!readonly || !!this.data.inspection.fumigation) && (<IonItem lines="none" className="checkboxItem" key="inspection.fumigation">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="inspection.fumigation"
                      readonly={readonly}
                      checked={!!this.data.inspection.fumigation}
                      onIonChange={this.onStringFieldChange}
                    />
                    <IonLabel className="ion-padding-horizontal" color={this.data.inspection.fumigation && "primary"}>
                      {UIText.rfqForm.fumigation}
                    </IonLabel>
                  </IonItem>
                )}
                {(!readonly || !!this.data.inspection.phytosanitary) && (<IonItem lines="none" className="checkboxItem" key="inspection.phytosanitary">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="inspection.phytosanitary"
                      readonly={readonly}
                      checked={!!this.data.inspection.phytosanitary}
                      onIonChange={this.onStringFieldChange}
                    />

                    <IonLabel className="ion-padding-horizontal" color={this.data.inspection.phytosanitary && "primary"}>
                      {UIText.rfqForm.phytosanitary}
                    </IonLabel>
                  </IonItem>
                )}
                {(!readonly || !!this.data.inspection.qualityTest) && (<IonItem lines="none" className="checkboxItem" key="inspection.qualityTest">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="inspection.qualityTest"
                      readonly={readonly}
                      checked={!!this.data.inspection.qualityTest}
                      onIonChange={this.onStringFieldChange}
                    />

                    <IonLabel className="ion-padding-horizontal" color={this.data.inspection.qualityTest && "primary"}>
                      {UIText.rfqForm.qualityTest}
                    </IonLabel>

                  </IonItem>
                )}
                {(!readonly || (!!this.data.inspection.other || !!this.data.inspection._otherChecked)) && (<IonItem lines="none" className="checkboxItem" key="inspection.other">
                    <IonCheckboxWithReadonly
                      hideOnNoSelect
                      name="inspection._otherChecked"
                      readonly={readonly}
                      checked={!!this.data.inspection.other || !!this.data.inspection._otherChecked}
                      onIonChange={this.onStringFieldChange}
                    />

                    <IonLabel className="ion-padding-horizontal" color={(!!this.data.inspection.other || !!this.data.inspection._otherChecked) && "primary"}>
                      {UIText.rfqForm.otherInspection}
                    </IonLabel>
                  </IonItem>
                )}
              </IonButtons>
            </IonItem>
          </IonList>}

          {this.data.inspection && (this.data.inspection.other || this.data.inspection._otherChecked) && (!readonly || !isEmpty(this.data.inspection.other)) && (
            <IonList className="ion-no-padding">
              <IonItem lines="full">
                <IonLabel position="floating">
                  {UIText.rfqForm.otherInspection}
                </IonLabel>
                <TwoStageInput
                  readonly={readonly}
                  name="inspection.other"
                  heading={UIText.rfqForm.otherInspection}
                  value={this.data.inspection.other}
                  onIonChange={this.onStringFieldChange}
                  endIcon={ellipsisHorizontal}
                />
              </IonItem>
            </IonList>
          )}
        </IonList>

      </IonContent>
    )
  }
}

export default RfqForm;